<template>
  <div
    class="d-flex flex-column h-100 align-items-center justify-content-center text-center"
  >
    <div v-show="!loading && successful" class="mx-3">
      <h1>Bienvenido a {{ companyName }}</h1>
      <div class="mt-4">
        Gracias por confirmar tu cuenta. ¡Estamos listos para realizar tus
        envíos!
      </div>
    </div>
    <div v-show="!loading && !successful" class="mx-3">
      <h1>Ocurrio un error</h1>
      <div class="mt-4">
        <strong class="mt-2">No pudimos activar su cuenta</strong><br />
        {{ error }}
      </div>
    </div>

    <div class="m-5">
      <router-link id="register-btn" :to="{ name: 'login' }">
        <z-button expanded>Iniciar sesión</z-button>
      </router-link>
    </div>
    <loading-spinner v-show="loading" />
  </div>
</template>

<script>
export default {
  name: "Confirmation",

  data() {
    return {
      successful: null,
      loading: false,
      error: ""
    };
  },

  computed: {
    companyName() {
      return this.$store.getters["companyName"];
    }
  },

  created() {
    this.confirmAccount();
  },

  methods: {
    confirmAccount() {
      this.loading = true;
      this.$store
        .dispatch("user/confirmAccount", this.$route.params.id)
        .then(res => {
          console.log("Exito", res);
          this.successful = true;
          this.error = "";
        })
        .catch(err => {
          this.$captureError(err);
          this.error = this.handleError(err);
          this.successful = false;
        })
        .finally(() => (this.loading = false));
    },

    handleError(err) {
      switch (err.statusCode) {
        case 404:
          return "El usuario no fue encontrado o ya esta activado. Intenta ingresar con tu cuenta";
        case 500:
          return "Ocurrio un error interno, vuelve a intentarlo";
        default:
          return `Ocurrio un error desconocido ${err}`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 40px;
}

div {
  font-size: 14px;
}
</style>
